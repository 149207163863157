<template>
  <div
    @dragstart="dragStart($event)"
    @dragend="dragEnd($event)"
    :draggable="draggable"
    :class="{
      dragged: isDragged,
      'border-primary': isSelected,
      'is-draft' : isDraft,
    }"
    class="card user-select-none"
  >
    <!-- HEADER -->
    <div
      @click="editSession"
      class="session-header-container d-flex align-items-center position-relative px-2 py-3"
    >
      <div @click.stop class="form-check">
        <input
          @click="selectSession($event)"
          :checked="isSelected"
          :id="`cb-${session.sessionId}`"
          type="checkbox"
          class="form-check-input"
        >
        <label :for="`cb-${session.sessionId}`" class="form-check-label"></label>
      </div>

      <div class="session-header flex-grow-1 lh-sm small text-break fw-bold" role="button">
        <span v-if="isDraft" class="text-muted fw-normal fst-italic">(Draft)</span>
        {{ session.name }}
      </div>

      <fa-icon
        @click.stop
        @mousedown.stop="setDraggable(true)"
        @mouseup.stop="setDraggable(false)"
        :icon="['fas', 'arrows-alt']"
        :class="dragIconClass"
        class="ms-2"
      ></fa-icon>
    </div>

    <!-- EXERCISES -->
    <div v-if="session.exercises.length" class="small border-top p-2">
      <div
        v-for="(exercise, exerciseIndex) in session.exercises"
        :key="`key-${exerciseIndex}-${exercise.exerciseId}`"
        class="d-flex align-baseline"
      >
        <ExerciseBadge
          v-if="exercise.type !== 'section'"
          :exercise="exercise"
          :exercises="session.exercises"
          :variant="'text'"
          class="me-1"
        ></ExerciseBadge>

        <div class="flex-grow-1">
          <div :class="exercise.type === 'section' ? 'fw-bold' : ''">
            <span
              @click="showExerciseDescription(exercise)"
              v-if="isCompact && exercise.description && exercise.description !== ''"
              role="button"
            >
              <span class="me-1">{{ exercise.name }}</span>
              <fa-icon :icon="['fas', 'info-circle']" class="me-1 text-muted"></fa-icon>
            </span>
            <span v-else class="me-1">{{ exercise.name }}</span>
            <span v-if="exercise.type !== 'section' && getExerciseStatus(exercise) !== null">
              <fa-icon
                v-if="getExerciseStatus(exercise)"
                :icon="['fas', 'check']"
                class="text-primary"
              ></fa-icon>
              <fa-icon
                v-else :icon="['fas', 'times']"
                class="text-danger"
              ></fa-icon>
            </span>
          </div>

          <div
            v-if="!isCompact && exercise.description && exercise.description !== ''"
            v-html="$options.filters.nl2br(exercise.description)"
            class="smaller text-muted"
          ></div>

          <ExerciseSetRep
            v-if="!isCompact"
            :sets="$options.filters.exerciseSetParsed(exercise)"
          ></ExerciseSetRep>

          <TextView
            v-if="!isCompact && getExerciseNote(exercise) && getExerciseNote(exercise) !== ''"
            :text="getExerciseNote(exercise)"
            :useQuote="true"
            class="my-1"
          ></TextView>

          <ExerciseVideoList
            v-if="!isCompact && getExerciseVideos(exercise) && getExerciseVideos(exercise).length"
            :videos="getExerciseVideos(exercise)"
            class="small my-1"
          ></ExerciseVideoList>
        </div>
      </div>
    </div>

    <div v-else class="p-2 small text-muted fst-italic border-top">No exercises</div>

    <portal to="modal">
      <transition name="modal">
        <ExerciseDescriptionModal
          @close="hideExerciseDescription"
          v-model="showExerciseDescriptionModal"
          v-if="showExerciseDescriptionModal && activeExercise !== null"
          :exercise="activeExercise"
        ></ExerciseDescriptionModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CalendarSessionItem',
  components: {
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
    TextView: () => import('@/components/TextView'),
    ExerciseVideoList: () => import('@/components/ExerciseVideoList'),
    ExerciseDescriptionModal: () => import('@/components/modal/ExerciseDescriptionModal'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('config', ['configUICalendarViewMode']),
    ...mapGetters('calendar', ['calendarGetSelectedSessions', 'calendarGetSessionIsSelected']),
    isCompact() {
      return this.configUICalendarViewMode === 'compact';
    },
    isDraft() {
      return this.session.status === 'draft';
    },
    isSelected() {
      return this.calendarGetSessionIsSelected(this.session.sessionId);
    },
    isFirstInSelectedSessions() {
      const { sessionId } = this.session;
      const index = this.calendarGetSelectedSessions.findIndex((s) => s.sessionId === sessionId);
      return index === 0;
    },
    dragIconClass() {
      const { calendarGetSelectedSessions, isFirstInSelectedSessions } = this;
      if (!calendarGetSelectedSessions.length || isFirstInSelectedSessions) return '';
      return 'invisible no-interaction';
    },
  },
  methods: {
    setDraggable(bool) {
      this.draggable = bool;
    },
    dragStart(e) {
      const { sessionId, date } = this.session;
      e.dataTransfer.setData('sessionId', sessionId);
      e.dataTransfer.setData('sessionDate', date);
      this.isDragged = true;
    },
    dragEnd() {
      this.isDragged = false;
    },
    editSession() {
      this.$store.dispatch('calendar/setSessionInEditing', this.session.sessionId);
    },
    selectSession(e) {
      const { sessionId } = this.session;
      if (this.isSelected) {
        this.$store.dispatch('calendar/deleteSelection', { sessionId });
      } else {
        this.$store.dispatch('calendar/addSelection', {
          sessionId,
          isMultiple: e.shiftKey,
        });
      }
    },
    getExerciseStatus(exercise) {
      const { exerciseStatuses } = this.session;
      if (!exerciseStatuses) return null;
      const { exerciseId } = exercise;
      const status = exerciseStatuses.find((s) => s.exerciseId === exerciseId);
      if (status && status.isCompleted !== null) {
        return !!status.isCompleted;
      }
      return null;
    },
    getExerciseNote(exercise) {
      const { exerciseStatuses } = this.session;
      if (!exerciseStatuses) return null;
      const { exerciseId } = exercise;
      const status = exerciseStatuses.find((s) => s.exerciseId === exerciseId);
      return status ? status.note : null;
    },
    getExerciseVideos(exercise) {
      const { exerciseVideos } = this.session;
      if (!exerciseVideos) return null;
      const { exerciseId } = exercise;
      const videos = exerciseVideos.filter((v) => v.exerciseId === exerciseId);
      return videos;
    },
    showExerciseDescription(exercise) {
      this.activeExercise = exercise;
      this.showExerciseDescriptionModal = true;
    },
    hideExerciseDescription() {
      this.showExerciseDescriptionModal = false;
      this.activeExercise = null;
    },
  },
  data() {
    return {
      draggable: false,
      isDragged: false,
      showExerciseDescriptionModal: false,
      activeExercise: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.session-header-container {
  border-radius: calc(#{$borderRadius} - 1px) calc(#{$borderRadius} - 1px) 0 0;
  background-color: transparent;
  padding-bottom: .5rem !important;
}

.form-check {
  position: absolute;
  top: -1rem;
  margin: 0;

  > .form-check-input {
    width: 1.5em;
    height: 1.5em;
  }
}

.is-draft {
  opacity: .5;
}
</style>
